
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import IUser from "@/mixins/interface/IUser";

const UserModule = namespace("user");

@Component({
  components: { SaveButton, DefaultForm },
  mixins: [FormMixin],
})
export default class UserForm extends Vue {
  protected name = "UserForm.vue";

  @UserModule.Action("load")
  protected loadUsersAction: any;

  @UserModule.Getter("getIsLoading")
  protected isLoading: any;

  @UserModule.Getter("getIsSaving")
  protected isSaving: any;

  @UserModule.Getter("getValidationErrors")
  protected getValidationErrors: any;

  @UserModule.Getter("getError")
  protected getError: any;

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IUser;

  protected id?: number;
  protected uName = "";
  protected email = "";
  protected password = "";
  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: IUser) {
    if (data) {
      this.setFormValues(data);
    }
  }

  protected doSomething(): void {
    //ghdgh
  }

  public getFormValues(): IUser {
    let user: IUser = {
      email: this.email,
      name: this.uName,
      password: this.password,
    };
    if (this.data && this.data.id) {
      user.id = this.id;
    }
    return user;
  }

  protected setFormValues(data: IUser): void {
    console.log("set form values ..", data);
    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.uName = data.name;
    }
  }

  protected init() {}
}
