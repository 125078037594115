
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import UserForm from "@/views/User/UserForm.vue";

const UserModule = namespace("user");

@Component({
  components: { UserForm },
  mixins: [UpdateMixin],
})
export default class UserUpdate extends Vue {
  protected name = "UserUpdate.vue";

  @UserModule.Action("findOne")
  protected loadItemAction: any;

  @UserModule.Action("update")
  protected updateItemAction: any;

  @UserModule.Getter("getDataItem")
  protected getItem: any;

  @UserModule.Getter("getIsLoading")
  protected isLoading: any;

  protected init(): void {
    this.loadItemAction({ id: this.$route.params.id, resource: this.resource });
  }

  public get resource(): string {
    return "/user";
  }

  public get descriptionField(): string {
    return "url";
  }
}
